<template>
  <el-row :gutter="10">

    <el-col
      v-for="n in scaleConfig.varsAmount"
      :key="n"
      class="card-view-number-multiple-col"
      :span="scaleConfig.viewSpan"
    >

      <div v-if="scaleConfig.varsAmount===3"> <!-- ABC -->
        <b>{{variables.colNameMapFor3[n]}}</b>: {{calcValue(n)}}
      </div>
      <div v-else>
        <b>Цель {{n}}</b>: {{calcValue(n)}}
      </div>
    </el-col>

  </el-row>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import {variables} from "../../variables";

export default {
  name: "card-view",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  data() {
    return {
      scale: {
        targets: {},
        pays: {},
      },
    }
  },
  mounted() {

  },
  computed: {
    variables() {
      return variables
    }
  },
  methods: {
    calcValue(n){
      if( this.scaleConfig.colsConfig && this.scaleConfig.colsConfig['col'+n] && this.scaleConfig.colsConfig['col'+n].percentFrom ){
        return this.valueFromPercent(this.scaleConfig.colsConfig['col'+n].percentFrom, this.scale.targets['col'+n])
      }

      return this.scale.targets['col'+n];
    },


    valueFromPercent(from, value) {
        value = value ? value : '0';
        value = parseFloat(value.replace(',', '.'));

        from = this.scale.targets[from] ? this.scale.targets[from] : '0';
        from = parseFloat(from.replace(',', '.'));

        let counted = from * value / 100;
        return Math.round(counted * 100)/100;
    }
  }
}
</script>

<style>
</style>